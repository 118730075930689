.login-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: 100vh;

    background-image: url("../../../public/assets/images/login/background.png");
    background-repeat: no-repeat;
    background-size: cover;
}

section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3em !important;
    width: 350px !important;
    height: auto;
    background-color: #ffff;
}

form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    gap: .5em;

    width: 100%;

    fieldset {
        &:first-child{
            margin-bottom: 1em;
        }

        position: relative;
        display: flex;
        flex-direction: row;
    
        gap: 1em;
        width: 100%;

        border: none;
    
        img{
            height: 25px !important;
            width: 25px !important;
        }
    
        span{
            position: absolute;
            background-color: white;
    
            top: 0;
            left: 0;
            
            font-size: 10px;
            text-align: center;
            font-weight: 600;

            width: 40px;
            height: min-content;

            margin-top: -6px;
            margin-left: 50px;
        }
    
        input{
            width: 100%;
            height: 25px !important;

            padding: 0 1em;

            border: .5px solid black;
        }
    }

    button{
        color: white;
        background-color: #FF6600;

        width: 100%;

        margin-top: 3.5em !important;
        padding: 0.5em !important;

        border: none;
        border-radius: 4px;

        cursor: pointer;
    }

    .ReCAPTCHA{
        justify-content: center;
        display: flex;
        width: 100%;
    }
}

.forgot-password{

    margin-left: auto;
    text-align: right;
    padding: 0;

    margin-bottom: -.2em;

    text-decoration: underline;
    cursor: pointer;
}

.logo{
    margin-bottom: 2em;
    margin-top: -2em;
    width: 180px;

    cursor: pointer;
}

footer{
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 16px;

    padding-top: .5em;
}