header {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;

    .unlog-container {

        position: absolute;

        display: flex;
        flex-direction: column;

        gap: 4px;
        top: 0;
        right: 0;
        margin: 1em 1.5em;

        p{
            font-size: 14px
        }

        .unlog-button {
            padding: .2em 2.5em;

            border: 1px solid #FF6600;
            border-radius: 4px;

            color: #FF6600;
            background-color: transparent;

            cursor: pointer;

            &:hover {
                background-color: #FF6600;
                color: white;
            }
        }
    }

    img {
        margin-left: -3em;
        width: 110px;
    }

    .navigation {
        display: flex;
        align-items: center;

        color: white;
        background-color: #FF6600;

        width: 100vw;

        ul {
            display: flex;
            align-items: center;

            flex-direction: row;

            padding: .5em 2em;

            gap: 2em;
        }
    }

    ul {
        list-style-type: none;
    }
}