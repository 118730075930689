.box1 {
    background-color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 75px;
    width: 100%;
}

.concluido img {
    width: 50px;
    height: 50px;
}

.iconeDownload {
    width: 200px;
    height: 200px;
}

.botaoDownload {
    background-color: #ff6600;
    color: #fff;
    width: 150px;
    height: 40px;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    font-size: 15px;
    font-weight: 600;
}

#body {
    width: 100%;

}

.botaoCancelar {
    background-color: #d3d3d3;
    color: black;
    width: 150px;
    height: 40px;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    font-size: 15px;
    font-weight: 600;
}

.fundo {
    background-color: #fff;
    height: 560px;
    width: 100%;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    animation: 2.5s cubic-bezier(.25, 1, .30, 1) wipe-cinematic-in both;
}

@keyframes wipe-cinematic-in {
    0% {
        clip-path: inset(100% 0 100% 0);
    }

    30%,
    70% {
        clip-path: inset(10% 0 10% 0);
    }

    100% {
        clip-path: inset(0 0 0 0);
    }
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.concluido {
    font-size: 22px;
    font-weight: 500;
    text-align: center;

    margin-left: -1em;

    color: #616161;
}

.download-area{
    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: -1.5em;

    .buttons{
        display: flex;
        gap: 1.5em;
        flex-direction: column;
    }
}