.progress-bar-percentage {
    font-family: 'Montserrat';
    font-size: 15px;
    margin-top: 10px;
}

.progress-bar-fill {
    height: 30px;
    border-radius: 10px;
    background-color: #FF6600;
    width: 731px;
    height: 19px;
    border-radius: 4px;
    transition: width 0.3s ease-in-out;
}

.progress-label {
    font-size: 24px;
    font-family: 'Montserrat';
    color: #616161;
    font-weight: 500;
}

.progress-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    border: l dashed 3px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    animation: loader-circle 2s linear infinite;
    margin-top: 5px;
}

.center {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 3.2em;
}

.preparingcenter {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    justify-content: center;
    margin-top: 30px;
}

@keyframes loader-tres-pontinhos {
    0% {
        transform: scale(.5);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.dots {
    display: flex;
}

.dot-animated {
    background-color: #616161;
    border-radius: 100%;
    width: 5px;
    height: 5px;
    margin: 6px 2px;
    animation: loader-tres-pontinhos 2s infinite;
}

.center-column {
    width: 100%;
}

.progress-bar {
    margin-top: 2em !important;

    background-color: #D9D9D9;
    width: 27em;
    height: 19px;
    border-radius: 4px;

    margin: 0 auto;
}

.loader {
    width: 40px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 5px solid #D9D9D9;
    border-right-color: #FF6600;

    margin-top: 2em;

    animation: l2 1s infinite linear;
}

@keyframes l2 {
    to {
        transform: rotate(1turn)
    }
}
