.login-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: 100vh;

    background-image: url("../../../public/assets/images/login/background.png");
    background-repeat: no-repeat;
    background-size: cover;

    p{
        margin-bottom: 2em;
    }
}

section {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 5em;

    width: 400px;
    height: auto;
    background-color: #ffff;
}

form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    gap: .5em;

    width: 100%;

    fieldset {
        &:first-child{
            margin-bottom: 1em;
        }

        position: relative;
        display: flex;
        flex-direction: row;
    
        gap: 1em;
        width: 100%;

        border: none;
    
        img{
            height: 28px;
            width: 27px;
        }
    
        span{
            position: absolute;
            background-color: white;
    
            top: 0;
            left: 0;
            
            font-size: 10px;
            text-align: center;
            font-weight: 600;

            width: 40px;
            height: min-content;

            margin-top: -6px;
            margin-left: 50px;
        }
    
        input{
            width: 100%;
            height: 30px;

            padding: 0 1em;

            border: .5px solid black;
        }
    }

    button{
        color: white;
        background-color: #FF6600;

        width: 100%;

        margin-top: 1.5em;
        padding: 1em;

        border: none;
        border-radius: 4px;

        cursor: pointer;
    }

    .ReCAPTCHA{
        margin-left: auto;
        margin-top: 1em;
        margin-bottom: -2em;
    }
}

.logo{
    margin-top: -2em;
    margin-bottom: 0 !important;
    width: 180px;

    cursor: pointer;
}