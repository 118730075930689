#baixar {
    color: #fff;
    background-color: #ff6600;

    width: 291px;

    border-radius: 4px;
    border: none;

    margin-top: 2em;
    margin-left: -1.3em;
    padding: 15px 10px;

    cursor: pointer;

    font-size: 15px;
    font-weight: 600;
}

.dropzone-container {
    margin-top: 3.7em;
    height: 20px;
}

.text-center {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
}

.img-pasta {
    height: 130px;
    width: 130px;
}

.column {
    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
        font-size: 22px;
        font-weight: 500;
        text-align: center;

        color: #616161;
    }
}

.column-active {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.resprem-active {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-bottom: -50px;
}

.text-center-active {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15% 40%;
    background-color: white;
    z-index: 1000;
    border: 3px dashed gray;
}

.App-intro {
    display: flex;
    align-items: center;
    color: white;
    font-size: 15px;
    font-family: 'Montserrat';
    justify-content: center;
    flex-direction: column;
}

.files-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    border: 1px solid black;
    border-radius: 5px;

    color: black !important;

    padding: 1em 0;

    margin-left: -1.2em;
    margin-top: 2em;
    width: 290px;

    ul {
        display: flex;
        list-style-type: none;
        margin-left: .7em;

        li {
            display: flex;
            align-items: center;
            gap: 1em;

            width: 100%;

            .remove-button {
                background-color: transparent;
                color: #ff6600;

                border: 1px solid #ff6600;
                border-radius: 4px;

                padding: .2em;

                font-weight: 500;

                cursor: pointer;

                &:hover {
                    background-color: #ff6600;
                    color: white;
                }
            }
        }
    }

    p {
        text-align: center;
        font-weight: 600;
    }
}

// Breaking File Modals 

.modal-loading-breaking img {
    width: 100px;
    height: auto;
}

.modal-loading-breaking {
    width: 100%;

    height: 300px;
    font-size: 18px;
    text-align: center;
    padding: 0;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-loading-breaking .mb-2 {
    color: black;
    font-size: 20px;
    font-family: 'Montserrat';
}