footer{
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;

    padding: 1em 0;

    @media (max-height: 486px) {
        display: none;
    }
}