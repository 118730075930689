.services-list{
    display: flex;

    p{
        margin-top: 4px;
    }

    .arrow-down{
        margin-left: -3em;
        margin-top: 6px;

        width: 16px;
    }

    span{
        position: relative;
        margin-left: 3.5em;
    }

    cursor: pointer;

    .tools-list{
        overflow: hidden !important;
        position: absolute;
        margin-top: 2em;

        width: 100%;
        height: 0px;
        z-index: 1000;
        left: 0;
        padding: 0 !important;

        background-color: #E1E1E1;

        border: none;

        color: black;

        transition: .2s all ease-in-out;

        ul:first-child{
            padding: .7em 2em;
        }

        ul{
            margin-top: 1em;

            li{
                p{
                    font-weight: 600;
                }
            }

            ul{
                margin-top: .5em;

                li{
                    display: flex;
                    align-items: center;

                    gap: 7px;

                    .resprem-document-icon{
                        width: 17px;
                    }

                    margin-top: 1em;
                    margin-left: 1em;

                    &:hover{
                        text-decoration: underline !important;
                    }
                }
            }
        }
    }

    &.show{
        
        .arrow-down{
            transform: scaleY(-1);   
        }

        .tools-list{
            overflow: visible;

            border-bottom: 2px solid black;

            padding: .5em;

            height: 150px;
        }
    }
}