.resprem {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    margin-top: -1.5em;
    margin-right: -1.5em;

    h1 {
        font-weight: 600 !important;
    }

    font-size: 20px;

    img {
        width: 100px;
    }
}